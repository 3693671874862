import { createContext, useContext, useReducer } from "react";
const AuthContext = createContext();

const initialState = {
  user: [],
  isAuthenticated: false,
  isLoading: false,
  loginMessage: null,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, isLoading: true };
    case "login":
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loginMessage: "Logged In",
        isLoading: false,
      };
    case "user/created":
      return {
        ...state,
        user: action.payload,
        isAuthenticated: false,
        loginMessage: "Account Created",
        isLoading: false,
      };
    case "user/updated":
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loginMessage: "Account Updated",
        isLoading: false,
      };

    case "user/updateProfilePic":
      return {
        ...state,
        user: {
          ...state.user,
          ProfilePic: action.payload,
        },
        isAuthenticated: true,
        loginMessage: "Account Updated",
        isLoading: false,
      };

    case "user/updateProfileMasthead":
      return {
        ...state,
        user: {
          ...state.user,
          ProfileMasthead: action.payload,
        },
        isAuthenticated: true,
        loginMessage: "Account Updated",
        isLoading: false,
      };

    case "logout":
      return { ...state, user: null, isAuthenticated: false, loginMessage: "" };
    case "rejected":
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        loginMessage: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

function AuthProvider({ children }) {
  const [{ user, isAuthenticated, loginMessage }, dispatch] = useReducer(
    reducer,
    initialState
  );

  async function createAccount(newAccount) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/account/`, {
        method: "POST",
        body: JSON.stringify(newAccount),
        headers: { "Content-Type": "application/json" },
      });
      const data = await res.json();
      console.log(data.Status);

      if (data.message === "Account Created") {
        dispatch({ type: "user/created", payload: newAccount });
      } else {
        dispatch({ type: "rejected", payload: data.message });
      }
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding user.",
      });
    }
  }

  async function updateAccount(theAccount) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/account/${user.CustUUID}`, {
        method: "POST",
        body: JSON.stringify(theAccount),
        headers: { "Content-Type": "application/json" },
      });
      const data = await res.json();
      console.log(data.Status);

      if (data.message === "Account Updated") {
        dispatch({ type: "user/updated", payload: data.user[0] });
      } else {
        dispatch({ type: "rejected", payload: data.message });
      }
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding user.",
      });
    }
  }

  async function updateProfilePic(profilepic) {
    dispatch({ type: "loading" });
    try {
      dispatch({
        type: "user/updateProfilePic",
        payload: profilepic,
      });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding user.",
      });
    }
  }

  async function updateProfileMasthead(masthead) {
    dispatch({ type: "loading" });
    try {
      dispatch({
        type: "user/updateProfileMasthead",
        payload: masthead,
      });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding user.",
      });
    }
  }

  async function login(email, password) {
    //API Connectivity and verification will go here...
    dispatch({ type: "loading" });
    try {
      const loginjson = {
        Email: `${email}`,
        Password: `${password}`,
      };

      const res = await fetch(`${baseUrl}/login/`, {
        method: "POST",
        body: JSON.stringify(loginjson),
        headers: { "Content-Type": "application/json" },
      });
      const data = await res.json();
      console.log(data.Status);
      if (data.Status === "Logged In") {
        dispatch({ type: "login", payload: data.UserInfo[0] });
      } else {
        dispatch({ type: "rejected", payload: data.Status });
      }
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error logging in.",
      });
    }
  }

  function logout() {
    dispatch({ type: "logout" });
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        loginMessage,
        login,
        createAccount,
        updateAccount,
        updateProfilePic,
        updateProfileMasthead,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("AuthContext was used outside AuthProvider");
  return context;
}

export { AuthProvider, useAuth };
