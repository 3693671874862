import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { MediaLink } from "../components/MediaLink";
function Media() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgURL = baseUrl + "/userfiles/" + user.CustUUID;

  const [filelist, setFilelist] = useState([]);

  const [loading, setLoading] = useState(true);

  // async function loadFiles(e) {
  //   e.preventDefault();

  //   const res = await fetch(`${baseUrl}/media/${user.CustUUID}`);
  //   const data = await res.json();
  //   const update = setFilelist(data);
  // }
  useEffect(() => {
    fetch(`${baseUrl}/media/${user.CustUUID}`)
      .then((res) => res.json())
      .then((data) => {
        setFilelist(data);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <section>
        <button
          className="MapButton"
          onClick={() => navigate(`/app/media/upload`)}
        >
          Upload
        </button>
        <h1>MEDIA: Coming Soon!</h1>
        <p>
          Store your photos and map them to memories, stories and locations!
        </p>

        <div className="MediaList">
          {loading ? (
            "Loading..."
          ) : (
            <>
              {filelist.map((files) => (
                <MediaLink
                  key={files.FileID}
                  Src={imgURL}
                  Picture={files.FileName}
                  FileID={files.FileID}
                />
                //<img src={`${imgURL}/${files.FileName}`} key={files.FileName} />
              ))}
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Media;
