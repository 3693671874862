import { useNavigate } from "react-router-dom";

export function MediaLink({ Src, Picture, FileID }) {
  const navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    navigate(`/app/media/${FileID}`);
  }
  return (
    <div className="MediaItem">
      {/* <img src={Picture} alt={id} /> */}
      <img src={`${Src}/${Picture}`} />
      <div className="Controls">
        <button
          class="Gear"
          title="Edit Media Settings"
          onClick={handleClick}
        ></button>
      </div>
    </div>
  );
}
