import { useState } from "react";
import { StoryList } from "../components/StoryList";
import { useNavigate } from "react-router-dom";
function Story() {
  const [showAddForm, setshowAddForm] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <section>
        <button
          className="BTN_Secondary"
          onClick={() => navigate(`/app/story/new`)}
        >
          Add Story
        </button>

        <StoryList></StoryList>
      </section>
    </>
  );
}

export default Story;
