import { MutualFriends } from "./MutualFriends";

export function FriendBlock({ friend }) {
  return (
    <div className="FriendBox">
      <span style={{ backgroundImage: "url(" + friend.Picture + ")" }}></span>
      <h3>{friend.Name}</h3>
      <MutualFriends
        CommonFriends={friend.CommonFriends}
        friends={friend.Friends}
      />
      <button className="BTN_Standard">Confirm</button>
      <button className="BTN_Secondary">Delete</button>
    </div>
  );
}
