export const friendsCurrent = [
  {
    id: "1",
    Name: "Fred Jones",
    Picture: "/media/images/Fred.jpg",
    CommonFriends: 8,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "Terry", Picture: "/media/avatars/Avatar Users2_30.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Daphne Blake",
    Picture: "/media/images/Daphne_Blake.webp",
    CommonFriends: 18,
    Friends: [
      { Name: "Nancy", Picture: "/media/avatars/Avatar Users2_22.jpg" },
      { Name: "Zachary", Picture: "/media/avatars/Avatar Users2_29.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Velma Dinkley",
    Picture: "/media/images/Velma.jpg",
    CommonFriends: 3,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "TBone", Picture: "/media/avatars/Avatar Users2_38.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Shaggy Rogers",
    Picture: "/media/images/Shaggy.webp",
    CommonFriends: 3,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "TBone", Picture: "/media/avatars/Avatar Users2_38.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Scooby Doo",
    Picture: "/media/images/ScoobyDoo.jpg",
    CommonFriends: 1,
    Friends: [
      { Name: "Larry", Picture: "/media/avatars/Avatar Users2_17.jpg" },
    ],
  },
];
