import { TagLink } from "./TagLink";
import { MediaLink } from "./MediaLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Dialog } from "../components/Dialog";
import { useStories } from "../contexts/StoriesContext";
import { useLocations } from "../contexts/LocationsContext";
import StoryLocation from "./StoryLocation";

export function StoryBlockPublic({ story }) {
  console.log("StoryTitle " + story.StoryTitle);
  const { deleteStory } = useStories();
  const { locations } = useLocations();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [storyLocations, setStoryLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const thisStartDate = new Date(story.StartDate);
  const thisEndDate = new Date(story.EndDate);

  const filteredLocations = locations.filter((item) => {
    const itemDate = new Date(item.StartDate);

    return itemDate >= thisStartDate && itemDate <= thisEndDate;
  });

  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }

  useEffect(() => {
    if (story.StoryLocations && story.StoryLocations.length > 0) {
      const storylocIDs = story.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${story.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryLocations(data);
          setLoading(false);
        });
    }
  }, []);

  return (
    <section className="Story">
      <div className="StoryDate">
        <p>
          {cbDate(story.StartDate)} - {cbDate(story.EndDate)}
        </p>
      </div>

      <div className={"StoryText " + story.Style}>
        <ul className="TagList">
          {/* <li>
            <a href="##">{tagArray}</a>
          </li> */}
          {/* {theseTags.map((tag) => (
            <TagLink key={tag.Tag} tag={tag} />
          ))} */}
          Tags:{" "}
          {story.StoryTags
            ? story.StoryTags.split(",").map((tag, index) => (
                <TagLink key={index} tag={tag.trim()} />
              ))
            : ""}
        </ul>
        <h3>{story.StoryTitle}</h3>
        <div
          className="StoryContent"
          dangerouslySetInnerHTML={{ __html: story.StoryText }}
        />

        <div className="MediaList">
          {/* {story.Media.map((media) => (
            <MediaLink key={media.ID} id={media.ID} Picture={media.Picture} />
          ))} */}
        </div>

        <>
          <p>{story.StoryLocations}</p>
          {storyLocations.length != 0 && (
            <>
              <h4>Location</h4>
              {storyLocations.map((place) => (
                <StoryLocation place={place} id={place.LocID} />
              ))}
            </>
          )}
        </>

        {/* {filteredLocations.length !== 0 && (
          <>
            <h4>Locations During This Time:</h4>
            <ul className="LocationList">
              {filteredLocations.map((place) => (
                <li key={place.LocID}>
                  {place.LocType === 1 ? (
                    <img src="../media/images/icon-home.png" />
                  ) : place.LocType === 2 ? (
                    <img src="../media/images/icon-school.png" />
                  ) : place.LocType === 3 ? (
                    <img src="../media/images/icon-work.png" />
                  ) : place.LocType === 4 ? (
                    <img src="../media/images/icon-event.png" />
                  ) : (
                    <img src="../media/images/icon-std.png" />
                  )}
                  {place.LocName} - {place.LocAddress}
                </li>
              ))}
            </ul>
          </>
        )} */}
      </div>
    </section>
  );
}
