import { useCallback, useRef, useState, useEffect } from "react";
import { useStories } from "../contexts/StoriesContext";
import { useTags } from "../contexts/TagsContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import Message from "../components/Message";
import ReactTags from "../lib/ReactTags";

function StoryNew() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { getStory, currentStory, createStory, storyIsLoading, error } =
    useStories();
  const { gettag, tags, currenttag, createtag, updatetag, deletetag } =
    useTags();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [StoryTitle, setStoryTitle] = useState();
  const [StoryText, setStoryText] = useState();
  const [msgError, setMsgError] = useState(null);

  const [tagList, setTagList] = useState([]);
  const [newTags, setNewTags] = useState([]);

  const reactTags = useRef();

  const [range, setRange] = useState();
  const [lastChange, setLastChange] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [accessLevel, setAccessLevel] = useState(2);
  const onOptionChange = (e) => {
    setAccessLevel(Number(e.target.value));
  };

  const onDelete = useCallback(
    (tagIndex) => {
      setTagList(tagList.filter((_, i) => i !== tagIndex));
    },
    [tagList]
  );

  const onAddition = useCallback(
    (newTag) => {
      setTagList([...tagList, newTag]);
    },
    [tagList]
  );

  //   useEffect(
  //     function () {
  //       async function getStoryData() {

  //         await getStory(storyID);

  //         //Update State...
  //         setStartDate(currentStory.StartDate);
  //         setEndDate(currentStory.EndDate);
  //         setStoryTitle(currentStory.StoryTitle);
  //         setStoryText(currentStory.StoryText);
  //       }
  //       getStoryData();
  //     },
  //     [currentStory]
  //   );

  const onTitleChange = (title) => {
    setStoryTitle(title);
  };
  const onStoryChange = (story) => {
    setStoryText(story);
  };

  useEffect(
    function () {
      if (error == "") {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage 1" + loginMessage);
      } else if (error === null) {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage null" + loginMessage);
      } else if (error != "") {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage 2" + loginMessage);
      }
    },
    [error]
  );

  async function handleStoryEdit(e) {
    e.preventDefault();

    console.log("Saving Story...");
    console.log(StartDate);
    var custuuid = `${user.CustUUID}`;
    //tagtype 1: story, 2: location, 3: memory
    var tagtype = 1;

    if (!StartDate || !EndDate || !StoryTitle || !StoryText) {
      setMsgError("All Fields Required");
      return;
    }
    const newStory = {
      StartDate,
      EndDate,
      StoryTitle,
      StoryText,
      accessLevel,
      custuuid,
    };
    console.log(newStory);

    const newTagList = tagList.filter((theTag) =>
      !theTag.id
        ? {
            id: theTag.id,
            name: theTag.name,
            new: "new AAA",
            custuuid: custuuid,
          }
        : ""
    );

    const newTagListB = newTagList.map((theTag) =>
      !theTag.id
        ? {
            tagTitle: theTag.name,
            custuuid: custuuid,
          }
        : ""
    );

    const currentTagList = tagList.filter((theTag) =>
      theTag.id
        ? {
            ...tagList,
            id: theTag.id,
            name: theTag.name,
            new: "new",
            custuuid: custuuid,
          }
        : ""
    );

    console.log(newTagList);
    console.log(newTagListB);
    console.log(currentTagList);

    // let promise = new Promise((resolve, reject) => {
    //   createStory(newStory);
    // });

    // const result = await promise;
    await createStory(newStory);
    let theNewID = await getNewestStoryID();

    createtag(tagList, tagtype, theNewID);

    navigate(`/app/story`);
  }

  async function getNewestStoryID() {
    const res = await fetch(`${baseUrl}/stories/new/${user.CustUUID}`);

    const data = await res.json();
    // console.log(data);
    // console.log("data.StoryID " + data[0].StoryID);

    const theNewStoryID = data[0].StoryID;
    return theNewStoryID;
  }

  if (storyIsLoading) return <Spinner />;
  return (
    <section>
      <div className="PopForm showForm">
        <form>
          <h2>Add to your story</h2>
          <p className="instructions">
            Add a story about your childhood, a trip, or an event you went to!
          </p>
          <>
            {error != null && error.trim() !== "" && (
              <Message thisstyle={"MSGError"}>{error}</Message>
            )}
            {msgError != null && msgError.trim() !== "" && (
              <Message thisstyle={"MSGError"}>{msgError}</Message>
            )}
          </>
          <div className="RadioBtnGroup">
            <p>Who Can View This:</p>
            <label htmlFor="All" className="RadioBtn inline">
              <img
                src="/media/images/icon-access-unlocked.svg"
                alt="Everyone"
              />{" "}
              <span>Everyone</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="All"
              value="1"
              checked={accessLevel === 1}
              onClick={onOptionChange}
            />
            <label htmlFor="Friends" className="RadioBtn inline">
              <img src="/media/images/icon-access-friends.svg" alt="Friends" />{" "}
              <span>Friends</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="Friends"
              value="2"
              checked={accessLevel === 2}
              onClick={onOptionChange}
            />
            <label htmlFor="None" className="RadioBtn inline">
              <img src="/media/images/icon-access-locked.svg" alt="No One" />{" "}
              <span>No One</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="None"
              value="3"
              checked={accessLevel === 3}
              onClick={onOptionChange}
            />
          </div>

          <div className="FormLeft">
            <label htmlFor="fromDate">From:</label>
            <DatePicker
              className="SearchDate"
              selected={StartDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              isClearable
            />
          </div>
          <div className="FormRight">
            <label htmlFor="toDate">To:</label>
            <DatePicker
              className="SearchDate"
              selected={EndDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              isClearable
            />
          </div>

          <label htmlFor="StoryTitle">Title </label>
          <input
            type="text"
            name="StoryTitle"
            value={StoryTitle}
            onChange={(e) => onTitleChange(e.target.value)}
            placeholder="title..."
          />

          <label htmlFor="StoryText">The Story</label>
          <textarea
            id="StoryText"
            onChange={(e) => onStoryChange(e.target.value)}
            value={StoryText}
          />

          <ReactTags
            allowNew
            ref={reactTags}
            tags={tagList}
            suggestions={tags}
            noSuggestionsText="No matching tags"
            onDelete={onDelete}
            onAddition={onAddition}
          />
          <button className="BTN_Standard" onClick={handleStoryEdit}>
            Save Story
          </button>
          <BackButton />
        </form>
      </div>
    </section>
  );
}

export default StoryNew;
