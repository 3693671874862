import { useCallback, useRef, useState, useEffect } from "react";
import { useStories } from "../contexts/StoriesContext";
import { useTags } from "../contexts/TagsContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../components/Spinner";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import StoryLocation from "../components/StoryLocation";
import ReactTags from "../lib/ReactTags";
//import suggestions from "../data/countries";

function Story() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(params.id);

  const { getStory, currentStory, updateStory, storyIsLoading } = useStories();
  const { getTags, tags, currenttag, createtag } = useTags();

  const [storyID, setStoryID] = useState(Number(params.id));
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [storyTitle, setStoryTitle] = useState();
  const [storyText, setStoryText] = useState();
  const [accessLevel, setAccessLevel] = useState(2);
  const [tagList, setTagList] = useState([]);

  const [storyLocations, setStoryLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const reactTags = useRef();

  //tagtype     1: story,     2: location,    3: memory
  const tagtype = 1;

  const onDelete = useCallback(
    (tagIndex) => {
      setTagList(tagList.filter((_, i) => i !== tagIndex));
    },
    [tagList]
  );

  const onAddition = useCallback(
    (newTag) => {
      setTagList([...tagList, newTag]);
    },
    [tagList]
  );

  useEffect(
    function () {
      async function getStoryData() {
        //getStory sets currentStory
        await getStory(storyID);

        //Update State...
        setStoryID(storyID);
        setStartDate(currentStory.StartDate);
        setEndDate(currentStory.EndDate);
        setStoryTitle(currentStory.StoryTitle);
        setStoryText(currentStory.StoryText);
        setAccessLevel(currentStory.AccessLevel);
        setStoryLocations(currentStory.StoryLocations);
        //await setLocationData();
      }
      getStoryData();
    },
    [currentStory]
  );

  useEffect(() => {
    if (currentStory.StoryLocations && currentStory.StoryLocations.length > 0) {
      const storylocIDs = currentStory.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${user.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryLocations(data);
          setLoading(false);
        });
    }
  }, []);

  useEffect(
    function () {
      async function getTagData() {
        //getTags sets currenttag
        await getTags(tagtype, params.id);
        //Breaks on Initial Load???
        //setTagList(currenttag);
      }
      getTagData();
    },
    [params.id]
  );

  useEffect(
    function () {
      async function setTagData() {
        //setTagList(currenttag);

        setTagList(currenttag);
      }
      setTagData();
    },
    [currenttag]
  );

  const addLocation = () => {
    navigate(`/app/locationnew?storyID=${storyID}`);
  };

  const onTitleChange = (title) => {
    setStoryTitle(title);
  };
  const onStoryChange = (story) => {
    setStoryText(story);
  };

  const onOptionChange = (e) => {
    setAccessLevel(Number(e.target.value));
  };

  async function handleStoryEdit(e) {
    e.preventDefault();

    console.log("Saving Story...");
    console.log(startDate);
    var custuuid = `${user.CustUUID}`;

    if (!startDate || !endDate || !storyTitle || !storyText) return;
    const editStory = {
      storyID,
      startDate,
      endDate,
      storyTitle,
      storyText,
      accessLevel,
      custuuid,
    };
    console.log(editStory);

    await createtag(tagList, tagtype, storyID);

    //Below WORKS!!!!
    await updateStory(editStory, storyID); // <-- Add 'tags'
    navigate(`/app/story`);
  }
  if (storyIsLoading) return <Spinner />;
  return (
    <section>
      <div className="PopForm showForm">
        <form>
          <h2>Edit your story:</h2>
          <p className="instructions">
            For events that happen on a single day, put in the same "From" and
            "To" dates.
          </p>
          <div className="RadioBtnGroup">
            <p>Who Can View This:</p>
            <label htmlFor="All" className="RadioBtn inline">
              <img
                src="/media/images/icon-access-unlocked.svg"
                alt="Everyone"
              />{" "}
              <span>Everyone</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="All"
              value="1"
              checked={accessLevel === 1}
              onClick={onOptionChange}
            />
            <label htmlFor="Friends" className="RadioBtn inline">
              <img src="/media/images/icon-access-friends.svg" alt="Friends" />{" "}
              <span>Friends</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="Friends"
              value="2"
              checked={accessLevel === 2}
              onClick={onOptionChange}
            />
            <label htmlFor="None" className="RadioBtn inline">
              <img src="/media/images/icon-access-locked.svg" alt="No One" />{" "}
              <span>No One</span>
            </label>
            <input
              type="radio"
              name="AccessLevel"
              id="None"
              value="3"
              checked={accessLevel === 3}
              onClick={onOptionChange}
            />
          </div>
          <div className="FormLeft">
            <label htmlFor="fromDate">From:</label>
            <DatePicker
              className="SearchDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              isClearable
            />
          </div>
          <div className="FormRight">
            <label htmlFor="toDate">To:</label>
            <DatePicker
              className="SearchDate"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              isClearable
            />
          </div>
          <label htmlFor="StoryTitle">Title </label>
          <input
            type="text"
            name="StoryTitle"
            value={storyTitle}
            onChange={(e) => onTitleChange(e.target.value)}
            placeholder="title..."
          />
          <label htmlFor="StoryText">The Story</label>
          <textarea
            id="StoryText"
            onChange={(e) => onStoryChange(e.target.value)}
            value={storyText}
          />

          <>
            <p>{currentStory.StoryLocations}</p>
            <button onClick={addLocation}>Add Location</button>
          </>

          {/* <>
            {storyLocations.length != 0 && (
              <>
                <h4>Location</h4>
                {storyLocations.map((place) => (
                  <StoryLocation place={place} id={place.LocID} />
                ))}
              </>
            )}
          </> */}
          <hr />

          <ReactTags
            allowNew
            ref={reactTags}
            tags={tagList}
            suggestions={tags}
            noSuggestionsText="No matching tags"
            onDelete={onDelete}
            onAddition={onAddition}
          />
          <button className="BTN_Standard" onClick={handleStoryEdit}>
            Save Story
          </button>
          <BackButton />
        </form>
      </div>
    </section>
  );
}

export default Story;
