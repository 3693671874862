import { useLocations } from "../contexts/LocationsContext";
import { useState } from "react";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import {
  StandaloneSearchBox,
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  InfoWindowF,
  Autocomplete,
} from "@react-google-maps/api";
import { Dialog } from "../components/Dialog";
const mapCenter = { lat: 44.9591, lng: -89.6301 };

export function LocationList() {
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [selectedMarker, setSelectedMarker] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [delID, setDelID] = useState(null);
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };

  function handleEditLocation(Location) {
    console.log("Edit " + Location.LocID);
    navigate(`/app/locations/${Location.LocID}`);
  }

  function handleDelete(LocID) {
    setDelID(LocID);
    handleDialog("Are you sure you want to delete?", true);

    //navigate(`/app/story/${story.StoryID}`);
  }
  const areUSureDelete = (choose) => {
    if (choose) {
      //Dsend Del Function
      deleteLocation(delID);
      handleDialog("", false);
      setDelID(null);
    } else {
      handleDialog("", false);
      setDelID(null);
    }
  };

  const { locations, isLoading, deleteLocation } = useLocations();

  console.log(locations);

  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }

  if (isLoading) return <Spinner />;

  if (!locations.length) return <h2>No Locations</h2>;

  if (!isLoaded) {
    return <p>loading widget.. Spinner?</p>;
  }

  // const markerCoors = {
  //   lat: 39.1031,
  //   lng: -84.512,
  // };
  return (
    <>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}

      <GoogleMap
        center={mapCenter}
        zoom={7}
        mapContainerStyle={{
          width: "100%",
          height: "90vh",
        }}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        {/* <MarkerF position={mapCenter} /> */}
        {locations.map((location) => (
          <MarkerF
            position={{ lat: location.LocLat, lng: location.LocLng }}
            key={location.LocID}
            scale="1.1"
            width={150}
            height={150}
            options={{
              color: "ffffff",
              icon:
                location.LocType === 1
                  ? "../media/images/icon-map-home.png"
                  : location.LocType === 2
                  ? "../media/images/icon-map-school.png"
                  : location.LocType === 3
                  ? "../media/images/icon-map-work.png"
                  : location.LocType === 4
                  ? "../media/images/icon-map-event.png"
                  : "../media/images/icon-map-std.png",
              scale: 5.05,
              width: 150,
              height: 150,
            }}
            onClick={() => {
              setSelectedMarker(location);
            }}
          >
            {selectedMarker.LocID === location.LocID && (
              <InfoWindowF
                onCloseClick={() => setSelectedMarker(false)}
                position={{ lat: location.LocLat, lng: location.LocLng }}
              >
                <>
                  <h2>{location.LocTitle}</h2>
                  <p>{location.LocAddress}</p>
                  <p>
                    {cbDate(location.StartDate)} - {cbDate(location.EndDate)}
                  </p>
                  {/* <button
                    onClick={() => {
                      setSelectedMarker("");
                    }}
                  >
                    Edit
                  </button> */}

                  <button
                    className="BTN-mapFunction BTN-Edit"
                    onClick={() => {
                      handleEditLocation(location);
                    }}
                  ></button>
                  <button
                    className="BTN-mapFunction BTN-Delete"
                    onClick={() => {
                      handleDelete(location.LocID);
                    }}
                  ></button>
                </>
              </InfoWindowF>
            )}
          </MarkerF>
          // <MarkerF
          //   position={`lat: ${parseFloat(location.Lat)}, lng: ${parseFloat(
          //     location.Lng
          //   )}`}
          //   key={location.LocID}
          // ></MarkerF>
        ))}
      </GoogleMap>
    </>
  );
}

export default LocationList;
