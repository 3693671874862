import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../components/Spinner";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";

function AccountDetail() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const imgURL = baseUrl + "/userfiles/" + user.CustUUID;

  return (
    <section>
      <div className="ProfileHeader">
        <div className="ProfileMasthead">
          <img
            src={`${imgURL}/${user.ProfileMasthead}`}
            alt={`Masthead ${user.FirstName}&nbsp;${user.LastName}`}
          />
          <div className="Controls">
            <button
              class="Gear"
              title="Edit Profile Pic"
              onClick={() => navigate(`/app/accountedit/profilemasthead`)}
            ></button>
          </div>
        </div>
        <div className="ProfileAvatar">
          <img
            src={`${imgURL}/${user.ProfilePic}`}
            alt={`Avatar ${user.FirstName}&nbsp;${user.LastName}`}
          />
          <div className="Controls">
            <button
              class="Gear"
              title="Edit Profile Pic"
              onClick={() => navigate(`/app/accountedit/profilepic`)}
            ></button>
          </div>
        </div>
      </div>
      <div
        className="ProfileDetails"
        onClick={() => navigate(`/app/accountedit`)}
      >
        <h1>
          {user.FirstName} {user.LastName}
        </h1>
        <p>
          <strong>{user.Company}</strong> | <i>{user.ScreenName}</i>
        </p>
      </div>
      <p>
        <br />
        Birthday: {user.birthday}
        <br />
        Join Date: {user.JoinDate}
        <br />
        Email: {user.Email}
        <br />
        Phone 1: {user.Phone1}
        <br />
        Phone 2: {user.Phone2}
        <br />
      </p>
      <p>
        {user.Address1} {user.Address2}
        <br />
        {user.City} {user.Province} {user.ZIP}
        <br />
        {user.Country}
        <br />
        {user.WebAddress}
      </p>
      <button
        className="BTN_Secondary"
        onClick={() => navigate(`/app/accountedit`)}
      >
        Edit Profile
      </button>

      {/* <button
        className="BTN_Secondary"
        onClick={() => navigate(`/app/accountedit/profilepic`)}
      >
        Edit Profile Pic
      </button>
      <button
        className="BTN_Secondary"
        onClick={() => navigate(`/app/accountedit/profilemasthead`)}
      >
        Edit Profile Masthead
      </button> */}
    </section>
  );
}

export default AccountDetail;
