import { Link, NavLink } from "react-router-dom";

function SideNavigation() {
  return (
    <nav className="SideNav">
      <ul>
        <li>
          <NavLink to="/app/home" activeClassName="active">
            <img src="/media/images/nav-home.svg" alt="Return Home" />
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/app/story" activeClassName="active">
            <img src="/media/images/nav-story.svg" alt="View Your Story" />
            <span>Your Story</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/app/locations" activeClassName="active">
            <img
              src="/media/images/nav-locations.svg"
              alt="View Your Locations"
            />
            <span>Locations</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/app/timeline" activeClassName="active">
            <img
              src="/media/images/nav-timeline.svg"
              alt="View Your Timeline"
            />
            <span>Timeline</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/app/memories" activeClassName="active">
            <img
              src="/media/images/nav-memories.svg"
              alt="View Your Memories"
            />
            <span>Memory Rooms</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/app/connections" activeClassName="active">
            <img
              src="/media/images/nav-connections.svg"
              alt="View Your Connections"
            />
            <span>Connections</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/app/media" activeClassName="active">
            <img src="/media/images/nav-media.svg" alt="View Your Media" />
            <span>Media</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/app/chat" activeClassName="active">
            <img src="/media/images/nav-chat.svg" alt="View Your Chat Log" />
            <span>Chat</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default SideNavigation;
