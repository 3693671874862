function Media() {
  return (
    <>
      <section>
        <h1>CHAT : Coming Soon!</h1>
        <p>Chat with friends!</p>
      </section>
    </>
  );
}

export default Media;
