//import { userInfo } from "../data/userInfo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export function UserProfile() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const imgURL = baseUrl + "/userfiles/" + user.CustUUID;
  return (
    <div className="UserProfile" onClick={() => navigate(`/app/account`)}>
      <img
        src={`${imgURL}/${user.ProfilePic}`}
        alt={`${user.FirstName}&nbsp;${user.LastName}`}
      />
      <p>
        <strong>
          {user.FirstName}&nbsp;{user.LastName}
        </strong>
        <span>
          {user.City}, {user.Province}
        </span>
      </p>
    </div>
  );
}
