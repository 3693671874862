import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStories } from "../contexts/StoriesContext";

function MediaEdit() {
  const { stories, isLoading } = useStories();
  const navigate = useNavigate();
  const { user } = useAuth();
  const params = useParams();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const imgURL = baseUrl + "/userfiles/" + user.CustUUID;

  const [loading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState([]);

  const [DateCreated, setDateCreated] = useState();

  const [selectedStoryOption, setSelectedStoryOption] = useState("");
  const [mediaStories, setMediaStories] = useState([]);

  const handleStoryChange = (event) => {
    setSelectedStoryOption(event.target.value);
  };

  const handleAssignStory = () => {
    // Call BaseURL - send Media Assignment
    console.log(user.CustUUID);
    console.log(mediaData.FileID);
    console.log(selectedStoryOption);
    ///:custuuid/:fileid/:storyid
    fetch(
      `${baseUrl}/media/${user.CustUUID}/${params.id}/${selectedStoryOption}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMediaData(data);
        assignStoryTitles(data.StoryIDs);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(`${baseUrl}/media/${user.CustUUID}/${params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setMediaData(data);
        assignStoryTitles(data.StoryIDs);
        setLoading(false);
      });
  }, []);

  const assignStoryTitles = (ids) => {
    console.log(ids);
    fetch(
      `${baseUrl}/media/${user.CustUUID}/${params.id}/stories?StoryIDs=${ids}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMediaStories(data);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (mediaData.StoryIDs && mediaData.StoryIDs.length > 0) {
  //     const theseStoryIDs = mediaData.StoryIDs;
  //     fetch(
  //       `${baseUrl}/media/stories/${user.CustUUID}?StoryIDs=${theseStoryIDs}`,
  //       {
  //         method: "GET",
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setMediaStories(data);
  //         setLoading(false);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (mediaData.StoryIDs != null) {
      if (mediaData.StoryIDs.length > 0) {
        const theseStoryIDs = mediaData.StoryIDs;
        fetch(
          `${baseUrl}/media/stories/${user.CustUUID}?StoryIDs=${theseStoryIDs}`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setMediaStories(data);
            setLoading(false);
          });
      } else {
        // Handle the case when StoryIDs is an empty array
        console.log("StoryIDs is an empty array");
        setLoading(false);
      }
    }
  }, []);

  return (
    <>
      <section>
        <h1>Edit Media {mediaData.FileID}</h1>
        <p>Set your photos location and date.</p>

        <div className="MediaList">
          {loading ? (
            "Loading..."
          ) : (
            <>
              <img src={`${imgURL}/${mediaData.FileName}`} />

              <label htmlFor="fromDate">Date Taken:</label>
              <DatePicker
                className="SearchDate"
                selected={DateCreated}
                onChange={(date) => setDateCreated(date)}
                dateFormat="yyyy-MM-dd"
                isClearable
              />
            </>
          )}
        </div>

        <div>
          <label>Add to Story</label>
          <select value={selectedStoryOption} onChange={handleStoryChange}>
            <option> - Select Location -</option>
            {stories.map((story) => (
              <option key={story.StoryID} value={story.StoryID}>
                {story.StoryTitle}
              </option>
            ))}
          </select>
          <button onClick={handleAssignStory}>Assign</button>
        </div>
        <div>
          {mediaData.StoryIDs}
          {mediaStories && mediaStories.length > 0 && (
            <ul>
              {mediaStories.map((theStory) => (
                <li key={theStory.StoryID}>{theStory.StoryTitle}</li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
}

export default MediaEdit;
