import { MemorySnapList } from "../components/MemorySnapList";

function Memories() {
  return (
    <>
      <section>
        <h1>Memory Rooms Are Coming Soon!</h1>
        <p>Below is justa glimpse of what is coming!</p>
        <p>Share and colaborate on memories with friends!</p>
        <ul>
          <li>Share Media</li>
          <li>Post Locations & Events unique to the memory</li>
          <li>Create a Timeline unique to the memory</li>
          <li>Chat online and in our app!</li>
        </ul>
        <hr />
        <MemorySnapList />
      </section>
    </>
  );
}

export default Memories;
