import { MemorySnapshot } from "./MemorySnapshot";
import { memoryList } from "../data/memoryList";

export function MemorySnapList() {
  return (
    <div>
      {memoryList.map((memory) => (
        <MemorySnapshot memory={memory} key={memory.id} />
      ))}
    </div>
  );
}
