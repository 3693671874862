import LocationList from "../components/LocatonList";
import { useNavigate } from "react-router-dom";
function Locations() {
  const navigate = useNavigate();

  return (
    <>
      <section className="MapSection">
        <div className="AddLocationForm">
          <button
            className="MapButton"
            onClick={() => navigate(`/app/locationnew`)}
          >
            Add Location
          </button>
        </div>
        <LocationList></LocationList>
      </section>
    </>
  );
}

export default Locations;
