import { TagLink } from "./TagLink";
import { MediaBlock } from "./MediaBlock";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Dialog } from "../components/Dialog";
import { useStories } from "../contexts/StoriesContext";
import { useLocations } from "../contexts/LocationsContext";
import StoryLocation from "./StoryLocation";

export function StoryBlock({ story }) {
  const { deleteStory } = useStories();
  const { locations } = useLocations();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgURL = baseUrl + "/userfiles/" + story.CustUUID;

  const [storyLocations, setStoryLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const thisStartDate = new Date(story.StartDate);
  const thisEndDate = new Date(story.EndDate);
  const [storyMedia, setStoryMedia] = useState([]);
  const filteredLocations = locations.filter((item) => {
    const itemDate = new Date(item.StartDate);

    return itemDate >= thisStartDate && itemDate <= thisEndDate;
  });

  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  useEffect(() => {
    if (story.StoryLocations && story.StoryLocations.length > 0) {
      const storylocIDs = story.StoryLocations;
      fetch(
        `${baseUrl}/stories/locations/${story.CustUUID}?LocIds=${storylocIDs}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setStoryLocations(data);
          setStoryMedia(story.StoryMedia);
          setLoading(false);
        });
    }
  }, []);

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };

  function handleEdit(e) {
    e.preventDefault();
    navigate(`/app/story/${story.StoryID}`);
  }

  function handleDelete(e) {
    e.preventDefault();
    handleDialog("Are you sure you want to delete?", true);

    //navigate(`/app/story/${story.StoryID}`);
  }
  const areUSureDelete = (choose) => {
    if (choose) {
      //Dsend Del Function
      deleteStory(story.StoryID);
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  function cbDate(mydate) {
    const newDate = new Date(mydate);
    let theDate = newDate.toISOString().split("T")[0];
    return theDate;
  }

  return (
    <section className="Story">
      <div className="StoryDate">
        <p>
          {cbDate(story.StartDate)} - {cbDate(story.EndDate)}
        </p>

        {story.AccessLevel === 1 && (
          <img src="/media/images/icon-access-unlocked.svg" alt="Everyone" />
        )}
        {story.AccessLevel === 2 && (
          <img src="/media/images/icon-access-friends.svg" alt="Friends" />
        )}
        {story.AccessLevel === 3 && (
          <img src="/media/images/icon-access-locked.svg" alt="No One" />
        )}
      </div>

      <div className={"StoryText " + story.Style}>
        <ul className="TagList">
          {/* <li>
            <a href="##">{tagArray}</a>
          </li> */}
          {/* {theseTags.map((tag) => (
            <TagLink key={tag.Tag} tag={tag} />
          ))} */}
          Tags:{" "}
          {story.StoryTags
            ? story.StoryTags.split(",").map((tag, index) => (
                <TagLink key={index} tag={tag.trim()} />
              ))
            : ""}
        </ul>
        <h3>{story.StoryTitle}</h3>
        <div
          className="StoryContent"
          dangerouslySetInnerHTML={{ __html: story.StoryText }}
        />

        <div className="MediaList">
          {/* {story.Media.map((media) => (
            <MediaLink key={media.ID} id={media.ID} Picture={media.Picture} />
          ))} */}
        </div>

        <>
          {/* <p>{story.StoryLocations}</p> */}
          {storyLocations.length != 0 && (
            <>
              <h4>Location</h4>
              {storyLocations.map((place) => (
                <StoryLocation place={place} id={place.LocID} />
              ))}
            </>
          )}
        </>

        <hr />
        {/* <p>{story.StoryMedia}</p> */}
        <div className="MediaList">
          {story.StoryMedia
            ? story.StoryMedia.split(",").map((file, index) => (
                <MediaBlock key={index} Src={imgURL} Picture={file} />
              ))
            : ""}
        </div>

        {/* {filteredLocations.length !== 0 && (
          <>
            <h4>Locations During This Time:</h4>
            <ul className="LocationList">
              {filteredLocations.map((place) => (
                <li key={place.LocID}>
                  {place.LocType === 1 ? (
                    <img src="../media/images/icon-home.png" />
                  ) : place.LocType === 2 ? (
                    <img src="../media/images/icon-school.png" />
                  ) : place.LocType === 3 ? (
                    <img src="../media/images/icon-work.png" />
                  ) : place.LocType === 4 ? (
                    <img src="../media/images/icon-event.png" />
                  ) : (
                    <img src="../media/images/icon-std.png" />
                  )}
                  {place.LocName} - {place.LocAddress}
                </li>
              ))}
            </ul>
          </>
        )} */}

        <div className="Functions">
          <button
            className="BTN_Subtle BTN_Story"
            onClick={() => navigate(`/app/storymap/${story.StoryID}`)}
          >
            Map
          </button>

          <button className="BTN_Subtle BTN_Story" onClick={handleEdit}>
            Edit
          </button>

          <button className="BTN_Subtle BTN_Story" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
      {dialog.isLoading && (
        <Dialog onDialog={areUSureDelete} message={dialog.message} />
      )}
    </section>
  );
}
