import { useEffect, useCallback, useRef, useState } from "react";
import { useLocations } from "../contexts/LocationsContext";
import { useTags } from "../contexts/TagsContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../components/Spinner";
import ReactTags from "../lib/ReactTags";

import {
  StandaloneSearchBox,
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  InfoWindowF,
  Autocomplete,
} from "@react-google-maps/api";

import { setKey, fromLatLng } from "react-geocode";

import { useGeolocation } from "../hooks/useGeoLocation";

function LocationEdit() {
  const { getTags, tags, currenttag, createtag, updatetag, deletetag } =
    useTags();

  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);

  const inputRef = useRef();
  const clickedAddress = useRef();
  const reactTags = useRef();
  //tagtype     1: story,     2: location,    3: memory
  const tagtype = 2;

  const [tagList, setTagList] = useState([]);

  const onDelete = useCallback(
    (tagIndex) => {
      setTagList(tagList.filter((_, i) => i !== tagIndex));
    },
    [tagList]
  );

  const onAddition = useCallback(
    (newTag) => {
      setTagList([...tagList, newTag]);
    },
    [tagList]
  );

  useEffect(
    function () {
      async function getTagData() {
        //getTags sets currenttag
        await getTags(tagtype, params.id);
        //Breaks on Initial Load???
        //setTagList(currenttag);
      }
      getTagData();
    },
    [params.id]
  );

  useEffect(
    function () {
      async function setTagData() {
        //setTagList(currenttag);

        setTagList(currenttag);
      }
      setTagData();
    },
    [currenttag]
  );

  const {
    isLoading: isLoadingPosition,
    position: geolocationPosition,
    getPosition,
  } = useGeolocation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const mapCenter = { lat: 43.073051, lng: -89.40123 };
  const mapContainerStyle = {
    width: "100%",
    height: "90vh",
  };
  const mapOptions = {
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };
  useEffect(
    function () {
      if (geolocationPosition)
        setNewLocation([geolocationPosition.lat, geolocationPosition.lng]);
    },
    [geolocationPosition]
  );

  const onMapClick = (e) => {
    console.log(e);
    setNewLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setLocLat(e.latLng.lat());
    setLocLng(e.latLng.lng());

    fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        console.log(response.results[0]);
        setLocAddress(address);
        clickedAddress.current.value = address;
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const [mapZoom, setMapZoom] = useState(16);
  const [newLocation, setNewLocation] = useState({ mapCenter });

  const { updateLocation, currentLocation, getLocation, isLoading } =
    useLocations();
  const [locationID, setlocationID] = useState(Number(params.id));

  const [locType, setLocType] = useState(currentLocation.LocType);
  const [locLat, setLocLat] = useState("");
  const [locLng, setLocLng] = useState("");
  const [locName, setLocName] = useState("");
  const [locAddress, setLocAddress] = useState("");
  const [locTitle, setLocTitle] = useState("");
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [newTags, setNewTags] = useState([]);

  const onOptionChange = (e) => {
    setLocType(Number(e.target.value));
  };

  const showInfoWindow = () => {
    setInfoWindowOpen(true);
  };
  const onTitleChange = (e) => {
    setLocTitle(e.target.value);
    setLocName(e.target.value);
  };
  useEffect(
    function () {
      async function getLocationData() {
        //getStory sets currentStory
        await getLocation(locationID);

        //Update State...
        setStartDate(currentLocation.StartDate);
        setEndDate(currentLocation.EndDate);
        setLocTitle(currentLocation.LocTitle);
        setLocName(currentLocation.LocName);
        setLocAddress(currentLocation.LocAddress);
        setLocLat(currentLocation.LocLat);
        setLocLng(currentLocation.LocLng);
        setLocType(currentLocation.LocType);
        setNewLocation({
          lat: currentLocation.LocLat,
          lng: currentLocation.LocLng,
        });
      }
      getLocationData();
    },
    [currentLocation]
  );

  async function handleUpdate(e) {
    e.preventDefault();

    var custuuid = `${user.CustUUID}`;
    var locID = locationID;
    if (!locTitle || !StartDate) return;
    const editLocation = {
      locID,
      locType,
      locName,
      locTitle,
      locAddress,
      StartDate,
      EndDate,
      locLat,
      locLng,
      custuuid,
    };
    console.log(editLocation);

    await createtag(tagList, tagtype, locID);
    await updateLocation(editLocation);
    navigate(`/app/locations`);
  }

  return (
    <>
      <section>
        <GoogleMap
          center={newLocation}
          zoom={mapZoom}
          mapContainerStyle={mapContainerStyle}
          options={mapOptions}
          onLoad={(map) => setMap(map)}
          onClick={onMapClick}
          scrollWheelZoom={true}
        >
          {newLocation && (
            <MarkerF
              position={newLocation}
              onClick={showInfoWindow}
              options={{
                color: "ffffff",
                icon:
                  currentLocation.LocType === 1
                    ? "/media/images/icon-map-home.png"
                    : currentLocation.LocType === 2
                    ? "/media/images/icon-map-school.png"
                    : currentLocation.LocType === 3
                    ? "/media/images/icon-map-work.png"
                    : currentLocation.LocType === 4
                    ? "/media/images/icon-map-event.png"
                    : "/media/images/icon-map-std.png",
                scale: 0.55,
                width: 150,
                height: 150,
              }}
            >
              {infoWindowOpen && (
                <InfoWindowF onCloseClick={() => setInfoWindowOpen(false)}>
                  <></>
                </InfoWindowF>
              )}
            </MarkerF>
          )}
          <div className="MapForm">
            <form onSubmit={handleUpdate}>
              <input
                type="text"
                name="address"
                value={locAddress}
                ref={clickedAddress}
                placeholder="Where are you looking for?"
              />
              <label htmlFor="home" className="RadioBtn">
                Home
              </label>
              <input
                type="radio"
                name="LocType"
                id="home"
                value="1"
                checked={locType === 1}
                onClick={onOptionChange}
              />
              <label htmlFor="school" className="RadioBtn">
                School
              </label>
              <input
                type="radio"
                name="LocType"
                id="school"
                value="2"
                checked={locType === 2}
                onClick={onOptionChange}
              />
              <label htmlFor="work" className="RadioBtn">
                Work
              </label>
              <input
                type="radio"
                name="LocType"
                id="work"
                value="3"
                checked={locType === 3}
                onClick={onOptionChange}
              />
              <label htmlFor="event" className="RadioBtn">
                Event
              </label>
              <input
                type="radio"
                name="LocType"
                id="event"
                value="4"
                checked={locType === 4}
                onClick={onOptionChange}
              />

              <hr />
              <label htmlFor="fromDate">From:</label>
              <DatePicker
                className="SearchDate"
                selected={StartDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                isClearable
              />
              <label htmlFor="toDate">To:</label>
              <DatePicker
                className="SearchDate"
                selected={EndDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                isClearable
              />
              <hr />
              <label htmlFor="locTitle">Title</label>
              <input
                type="text"
                name="locTitle"
                value={locTitle}
                onChange={onTitleChange}
                placeholder="title..."
              />
              <hr />
              <ReactTags
                allowNew
                ref={reactTags}
                tags={tagList}
                suggestions={tags}
                noSuggestionsText="No matching tags"
                onDelete={onDelete}
                onAddition={onAddition}
              />
              <button className="MapButton BtnSave" onClick={handleUpdate}>
                Save Changes
              </button>
            </form>
          </div>
        </GoogleMap>
      </section>
    </>
  );
}

export default LocationEdit;
