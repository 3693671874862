import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AccountCreate() {
  const { createAccount, loginMessage } = useAuth();
  const [firstname, setfirstname] = useState();
  const [lastname, setlastname] = useState();
  const [birthday, setBirthDay] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const onFNameChange = (e) => {
    setfirstname(e.target.value);
  };

  const onLNameChange = (e) => {
    setlastname(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const onBdayChange = (e) => {
    setBirthDay(e.target.value);
  };

  useEffect(
    function () {
      if (loginMessage === "Account Created") {
        navigate("/", { replace: true });
      }
    },
    [loginMessage]
  );

  async function handleSave(e) {
    e.preventDefault();
    console.log("Save!");

    let status = 1;

    if (!firstname || !lastname || !email || !password) return;
    const newAccount = {
      firstname,
      lastname,
      birthday,
      status,
      email,
      password,
    };

    await createAccount(newAccount);
  }

  return (
    <section>
      <div className="FatForm">
        <h1>Create an account.</h1>
        <p>
          {loginMessage != null && (
            <Message thisstyle={"MSGError"}>{loginMessage}</Message>
          )}
        </p>
        <form>
          <label htmlFor="firstname">First Name</label>
          <input
            type="text"
            name="firstname"
            value={firstname}
            onChange={onFNameChange}
            defaultValue={firstname}
            placeholder="First Name..."
          />

          <label htmlFor="lastname">Last Name</label>
          <input
            type="text"
            name="lastname"
            value={lastname}
            onChange={onLNameChange}
            defaultValue={lastname}
            placeholder="Last Name..."
          />

          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={onEmailChange}
            defaultValue={email}
            placeholder="Email..."
          />

          <label htmlFor="birthDay">Birthday</label>

          <DatePicker
            selected={birthday}
            onChange={(date) => setBirthDay(date)}
            dateFormat="yyyy-MM-dd"
            isClearable
          />
          {/* <input
            type="date"
            name="birthDay"
            value={birthDay}
            onChange={onBdayChange}
            defaultValue={birthDay}
            placeholder="Email..."
          /> */}
          <hr />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={onPasswordChange}
            placeholder="Super Secret Password..."
          />

          <button className="BTN_Standard" onClick={handleSave}>
            Save & Continue
          </button>
        </form>
      </div>
    </section>
  );
}

export default AccountCreate;
