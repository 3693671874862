import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../components/Spinner";
import Message from "../components/Message";
import { useParams, useNavigate } from "react-router-dom";
import { StoryBlockPublic } from "../components/StoryBlockPublic";

function AccountSnapShot() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { screenname } = useParams();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserinfo] = useState([]);
  const [userstory, setUserstory] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/account/user/${screenname}`)
      .then((res) => res.json())
      .then((data) => {
        setUserinfo(data.user[0]);
        setUserstory(data.stories);

        setLoading(false);
      });
  }, []);

  return (
    <section>
      <div className="ProfileHeader">
        <div className="ProfileMasthead">
          <img
            src={`${baseUrl}/userfiles/${userInfo.CustUUID}/${userInfo.ProfileMasthead}`}
            alt={`Masthead ${userInfo.FirstName}&nbsp;${userInfo.LastName}`}
          />
        </div>
        <div className="ProfileAvatar">
          <img
            src={`${baseUrl}/userfiles/${userInfo.CustUUID}/${userInfo.ProfilePic}`}
            alt={`Avatar ${userInfo.FirstName}&nbsp;${userInfo.LastName}`}
          />
        </div>

        <div
          className="ProfileDetails"
          onClick={() => navigate(`/app/accountedit`)}
        >
          <h1>
            {userInfo.FirstName} {userInfo.LastName}
          </h1>
          <p>
            <strong>{userInfo.Company}</strong> | <i>{userInfo.ScreenName}</i>
          </p>
        </div>
      </div>
      <div className="PublicStory">
        {userstory.map((story) => (
          <StoryBlockPublic story={story} key={story.StoryID} />
        ))}
      </div>
    </section>
  );
}

export default AccountSnapShot;
