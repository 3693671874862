import { UserProfile } from "./UserProfile";

import { NotificationsMenu } from "./NotificationsMenu";

export function Header() {
  return (
    <header>
      <div className="LogoIcon">
        <img src="/media/images/bunny.png" alt="Logo Icon" />
      </div>
      <div className="Logo">
        <img src="/media/images/logo-CeramicBunny-100.jpg" alt="Logo Icon" />
      </div>
      <div className="UserInfo">
        <UserProfile />
        <NotificationsMenu />
      </div>
    </header>
  );
}
