import { FriendIcon } from "./FriendIcon";

export function MutualFriends({ CommonFriends, friends }) {
  return (
    <div className="MutualFriends">
      {friends.map((friend) => (
        <FriendIcon key={friend.Name} friend={friend} />
      ))}
      {CommonFriends} Mutual Friends
    </div>
  );
}
