import { friendsCurrent } from "../data/friendsCurrent";
import { FriendBlock } from "./FriendBlock";

export function FriendsCurrent() {
  return (
    <div className="CurrentFriends">
      <h2>Your Connections</h2>
      {friendsCurrent.map((friendsCur) => (
        <FriendBlock friend={friendsCur} key={friendsCur.id} />
      ))}
    </div>
  );
}
