import { FriendCount } from "./FriendCount";
import { FriendIcon } from "./FriendIcon";

export function MemorySnapshot({ memory }) {
  const moreFriends = Number(memory.FriendCount - memory.Friends.length);

  return (
    <div className="MemorySnapshot">
      <div className="Friends">
        {memory.Friends.map((friend) => (
          <FriendIcon key={friend.Name} friend={friend} />
        ))}
        {console.log("moreFriends " + moreFriends)}
        {moreFriends > 0 ? <FriendCount>+{moreFriends}</FriendCount> : ""}
      </div>
      <img src={memory.icon} alt={memory.Title} />
      <h3>{memory.Title}</h3>
      <div className="DateRange">
        {memory.StartDate} {!memory.EndDate ? "" : `- ${memory.EndDate}`}
      </div>
      <p>{memory.Description}</p>
      <div className="Controls">
        <button className="Gear" title="Edit Memory Settings"></button>
        <span className="Divider"></span>
        <button className="Images" title="Add/Edit Media">
          {memory.MediaCount}
        </button>
        <button className="Chat" title="View Chat Log">
          {memory.MessageCount}
        </button>
      </div>
    </div>
  );
}
