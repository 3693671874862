import { useRef, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useLocations } from "../contexts/LocationsContext";
import { useStories } from "../contexts/StoriesContext";
import { useAuth } from "../contexts/AuthContext";
import {
  StandaloneSearchBox,
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  InfoWindowF,
  Autocomplete,
} from "@react-google-maps/api";

import { setKey, fromLatLng } from "react-geocode";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGeolocation } from "../hooks/useGeoLocation";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapCenter = { lat: 43.073051, lng: -89.40123 };
// Madison lat:43.073051, lng: -89.401230//
//Wausau lat: 44.9591, lng: -89.6301 //
const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};
const mapOptions = {
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};
function LocationNew() {
  const { user } = useAuth();
  const {
    isLoading: isLoadingPosition,
    position: geolocationPosition,
    getPosition,
  } = useGeolocation();

  // const { storyID } = useParams();
  const query = useQuery();
  const storyID = query.get("storyID");

  console.log("storyID " + storyID);

  const { locations, createLocation, isLoading } = useLocations();
  const { stories, createStory } = useStories();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const [mapZoom, setMapZoom] = useState(6);
  const [newLocation, setNewLocation] = useState(mapCenter);

  useEffect(function () {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setNewLocation(pos);
      }
    );
  }, []);

  useEffect(
    function () {
      if (geolocationPosition)
        setNewLocation([geolocationPosition.lat, geolocationPosition.lng]);
    },
    [geolocationPosition]
  );

  const inputRef = useRef();
  const clickedAddress = useRef();
  const navigate = useNavigate();
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const [locType, setLocType] = useState(1);
  const [locLat, setLocLat] = useState("");
  const [locLng, setLocLng] = useState("");
  const [locName, setLocName] = useState("");
  const [locAddress, setLocAddress] = useState("");
  const [locTitle, setLocTitle] = useState("");

  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [markers, setMarkers] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddStory, setShowAddStory] = useState(false);
  const [storyText, setStoryText] = useState("");
  const [accessLevel, setAccessLevel] = useState(2);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const showInfoWindow = () => {
    setInfoWindowOpen(true);
  };

  if (!isLoaded) {
    return <p>loading widget.. Spinner?</p>;
  }

  const handleShowStory = () => {
    setShowAddStory((prevCheck) => !prevCheck);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      // console.log("place name " + place.name);
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());

      setLocName(place.name);
      setLocTitle(place.name);
      setLocAddress(place.formatted_address);
      setLocLat(place.geometry.location.lat());
      setLocLng(place.geometry.location.lng());
      setMapZoom(15);
      setNewLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setInfoWindowOpen(false);
      setShowAddForm(true);
      // console.log("newLocation " + newLocation);
    }
  };

  async function handleSave(e) {
    e.preventDefault();
    var custuuid = `${user.CustUUID}`;

    if (!locType || !locName || !locTitle || !locAddress) return;

    var StoryTitle = `${locTitle}`;
    var StoryText = `${storyText}`;
    // var StartDate = `${StartDate}`;
    // var EndDate = `${EndDate}`;

    const saveStory = {
      StoryTitle,
      StoryText,
      StartDate,
      EndDate,
      accessLevel,
      custuuid,
    };

    // If User Creates a Story
    if (storyID) {
      //Attach Story
      let theNewID = storyID;

      console.log(theNewID);
      const saveLocation = await setSaveLocation(
        locType,
        locName,
        locTitle,
        locAddress,
        StartDate,
        EndDate,
        locLat,
        locLng,
        custuuid,
        theNewID
      );
      await createLocation(saveLocation);
    } else if (StoryText != "") {
      console.log("save story");
      await createStory(saveStory);
      // Get the NEW Story ID
      let theNewID = await getNewestStoryID();

      console.log(theNewID);
      const saveLocation = await setSaveLocation(
        locType,
        locName,
        locTitle,
        locAddress,
        StartDate,
        EndDate,
        locLat,
        locLng,
        custuuid,
        theNewID
      );
      await createLocation(saveLocation);
    } else {
      console.log("no story");
      let theNewID = 0;
      const saveLocation = await setSaveLocation(
        locType,
        locName,
        locTitle,
        locAddress,
        StartDate,
        EndDate,
        locLat,
        locLng,
        custuuid,
        theNewID
      );
      await createLocation(saveLocation);
    }

    // const saveLocation = {
    //   locType,
    //   locName,
    //   locTitle,
    //   locAddress,
    //   StartDate,
    //   EndDate,
    //   locLat,
    //   locLng,
    //   custuuid,
    //   theNewID,
    // };

    //console.log(saveLocation);
    // console.log(saveStory);

    navigate(`/app/locations`);
  }

  async function setSaveLocation(
    locType,
    locName,
    locTitle,
    locAddress,
    StartDate,
    EndDate,
    locLat,
    locLng,
    custuuid,
    theNewID
  ) {
    const saveLocation = {
      locType,
      locName,
      locTitle,
      locAddress,
      StartDate,
      EndDate,
      locLat,
      locLng,
      custuuid,
      theNewID,
    };

    return saveLocation;
  }

  async function getNewestStoryID() {
    const res = await fetch(`${baseUrl}/stories/new/${user.CustUUID}`);

    const data = await res.json();
    // console.log(data);
    // console.log("data.StoryID " + data[0].StoryID);

    const theNewStoryID = data[0].StoryID;
    return theNewStoryID;
  }

  function handleReset() {
    setShowAddForm(false);
  }

  const onOptionChange = (e) => {
    setLocType(Number(e.target.value));
  };

  const onTitleChange = (e) => {
    setLocTitle(e.target.value);
    setLocName(e.target.value);
  };

  const onStoryChange = (e) => {
    setStoryText(e.target.value);
  };

  const onMapClick = (e) => {
    console.log(e);
    setNewLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setLocLat(e.latLng.lat());
    setLocLng(e.latLng.lng());

    fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        console.log(response.results[0]);
        setLocAddress(address);
        clickedAddress.current.value = address;
      },
      (error) => {
        console.error(error);
      }
    );
  };
  return (
    <>
      <section className="MapSection">
        {/* New Google Maps Box */}

        <GoogleMap
          center={newLocation}
          zoom={mapZoom}
          mapContainerStyle={mapContainerStyle}
          options={mapOptions}
          onLoad={(map) => setMap(map)}
          onClick={onMapClick}
          scrollWheelZoom={true}
          id="GMap"
        >
          {/* <Marker position={mapCenter}></Marker> */}

          {markers.map((marker) => (
            <MarkerF
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
            />
          ))}

          {newLocation && (
            <MarkerF position={newLocation} onClick={showInfoWindow}>
              {infoWindowOpen && (
                <InfoWindowF onCloseClick={() => setInfoWindowOpen(false)}>
                  <></>
                </InfoWindowF>
              )}
            </MarkerF>
          )}
        </GoogleMap>

        <div className="MapForm">
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <>
              <>
                <h2>
                  To Begin, Search a location, and then click the map to refine.
                </h2>
                <input
                  type="text"
                  name="inputRef"
                  id="inputRef"
                  ref={clickedAddress}
                  placeholder="Where are you looking for?"
                />
              </>

              {showAddForm && (
                <div className="SaveLocation">
                  <div>
                    <hr />
                    <div className="RadioBtnGroup">
                      <p>What Type Of Location:</p>
                      <label htmlFor="home" className="RadioBtn">
                        <img src="/media/images/icon-home.svg" alt="Home" />{" "}
                        <span>Home</span>
                      </label>
                      <input
                        type="radio"
                        name="LocType"
                        id="home"
                        value="1"
                        checked={locType === 1}
                        onClick={onOptionChange}
                      />
                      <label htmlFor="school" className="RadioBtn">
                        <img src="/media/images/icon-school.svg" alt="School" />{" "}
                        <span>School</span>
                      </label>
                      <input
                        type="radio"
                        name="LocType"
                        id="school"
                        value="2"
                        checked={locType === 2}
                        onClick={onOptionChange}
                      />
                      <label htmlFor="work" className="RadioBtn">
                        <img src="/media/images/icon-work.svg" alt="Work" />{" "}
                        <span>Work</span>
                      </label>
                      <input
                        type="radio"
                        name="LocType"
                        id="work"
                        value="3"
                        checked={locType === 3}
                        onClick={onOptionChange}
                      />
                      <label htmlFor="event" className="RadioBtn">
                        <img src="/media/images/icon-event.svg" alt="Event" />{" "}
                        <span>Event</span>
                      </label>
                      <input
                        type="radio"
                        name="LocType"
                        id="event"
                        value="4"
                        checked={locType === 4}
                        onClick={onOptionChange}
                      />
                    </div>
                    <hr />
                    <label htmlFor="fromDate">From:</label>
                    <DatePicker
                      className="SearchDate"
                      selected={StartDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      isClearable
                    />
                    <label htmlFor="toDate">To:</label>
                    <DatePicker
                      className="SearchDate"
                      selected={EndDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      isClearable
                    />
                    <hr />
                    <label htmlFor="locTitle" className="LocTitleLabel">
                      Title:
                    </label>
                    <input
                      type="text"
                      name="locTitle"
                      value={locTitle}
                      onChange={onTitleChange}
                      placeholder="title..."
                      className="LocTitleInput"
                    />

                    <div>
                      {storyID ? (
                        <h1>Story ID: {storyID}</h1>
                      ) : (
                        <>
                          <button
                            className="showStory"
                            onClick={handleShowStory}
                          >
                            + Story
                          </button>
                          <h1>No Story ID provided</h1>
                        </>
                      )}
                      {/* Your other component code */}
                    </div>
                    {showAddStory && (
                      <div className="AddStory">
                        <label htmlFor="storyText" className="LocStoryLabel">
                          Story:{" "}
                        </label>
                        <textarea
                          type="text"
                          name="storyText"
                          placeholder="Start your story..."
                          onChange={onStoryChange}
                          className="LocStoryInput"
                        />
                      </div>
                    )}
                    <hr />
                    <button className="MapButton BtnSave" onClick={handleSave}>
                      Save & Exit
                    </button>
                    {/* <button className="MapButton BtnSave">
                        Save & View Story
                      </button> */}
                  </div>
                  {/* <button onClick={handleReset}>Reset</button> */}
                </div>
              )}
            </>
          </StandaloneSearchBox>

          {/* <button onClick={() => map.panTo(newLocation)}>Center Me</button> */}
        </div>
      </section>
    </>
  );
}

function MapForm({ newLocation }) {
  return (
    <div>
      <label for="lat" value={newLocation}>
        Lat
      </label>
      <input name="Lat"></input>
      <label for="Lng">Lng</label>
      <input name="Lng"></input>
    </div>
  );
}

export default LocationNew;
