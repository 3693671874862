import { useState } from "react";
import UserMenu from "./UserMenu";
export function NotificationsMenu() {
  const [showMenu, setShowMenu] = useState(false);
  function handleShowMenu() {
    setShowMenu(!showMenu);
  }
  return (
    <div className="NotificationsMenu">
      <button className="Notifications"></button>
      <button className="DotMenu" onClick={handleShowMenu}></button>
      {showMenu && <UserMenu />}
    </div>
  );
}
