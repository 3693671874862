//import { storyList } from "../data/storyList";
import { StoryBlock } from "./StoryBlock";
import { useStories } from "../contexts/StoriesContext";

import { useState } from "react";

export function StoryList() {
  const { stories, isLoading } = useStories();

  return (
    <div className="StoryList">
      {stories.map((story) => (
        <StoryBlock story={story} key={story.StoryID} />
      ))}
    </div>
  );
}
