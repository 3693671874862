import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <h1>Demo App - My Life</h1>
        <ul className="HomeCards">
          <li onClick={() => navigate(`/app/story`)}>
            <img src="/media/images/nav-story.svg" alt="View Your Story" />
            <h2>Your Story</h2>
            <p>
              Document the story of your life. Details, Anecdotes, Reflections,
              etc.
            </p>
          </li>
          <li onClick={() => navigate(`/app/locations`)}>
            <img
              src="/media/images/nav-locations.svg"
              alt="View Your Locations"
            />
            <h2>Locations</h2>
            <p>
              Add the important places of your life: home, school, jobs, events
              and more!
            </p>
          </li>
          <li onClick={() => navigate(`/app/timeline`)}>
            <img
              src="/media/images/nav-timeline.svg"
              alt="View Your Timeline"
            />
            <h2>Timeline</h2>
            <p>See how your life maps out across time!</p>
          </li>
          <li onClick={() => navigate(`/app/memories`)}>
            <img
              src="/media/images/nav-memories.svg"
              alt="View Your Memories"
            />
            <h2>Memory Rooms</h2>
            <p>
              Create a room to share a group memory: concerts, fishing trips,
              sports, etc.
            </p>
          </li>
          <li onClick={() => navigate(`/app/connections`)}>
            <img
              src="/media/images/nav-connections.svg"
              alt="View Your Connections"
            />
            <h2>Connections</h2>
            <p>Connect with friends, family and coworkers.</p>
          </li>
          <li onClick={() => navigate(`/app/media`)}>
            <img src="/media/images/nav-media.svg" alt="View Your Media" />
            <h2>Media</h2>
            <p>Upload the pictures of your life's adventures!</p>
          </li>
        </ul>
      </section>
    </>
  );
}

export default Homepage;
