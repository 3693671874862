import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
function Media() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [files, setFiles] = useState([]);
  const changeFiles = (e) => {
    setFiles(e.target.files);
  };

  async function uploadFiles(e) {
    e.preventDefault();
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await fetch(`${baseUrl}/upload/${user.CustUUID}`, {
      method: "POST",
      body: formData,
    });
    navigate(`/app/media`);
  }

  return (
    <>
      <section>
        <h1>Upload a Picture!</h1>
        <p>
          Store your photos and map them to memories, stories and locations!
        </p>
        <p>*** In Development ***</p>

        <form
          id="uploadForm"
          encType="multipart/form-data"
          onSubmit={uploadFiles}
        >
          <input
            type="file"
            id="myfiles"
            accept="image/*"
            onChange={changeFiles}
          />
          <button>Submit</button>
        </form>
        <h2></h2>
        <h3></h3>
        <script></script>
      </section>
    </>
  );
}

export default Media;
