import { useEffect, useState } from "react";
import Button from "../components/Button";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import Message from "../components/Message";
export default function Login() {
  // PRE-FILL FOR DEV PURPOSES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login, isLoading, isAuthenticated, loginMessage } = useAuth();
  const [screenMsg, setScreenMsg] = useState(loginMessage);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("Logging in...");
    if (email && password) await login(email, password);
  }

  useEffect(
    function () {
      if (loginMessage == "") {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage 1" + loginMessage);
      } else if (loginMessage === null) {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage null" + loginMessage);
      } else if (loginMessage != "") {
        // setScreenMsg(loginMessage);
        // console.log("loginMessage 2" + loginMessage);
      }
    },
    [loginMessage]
  );

  useEffect(
    function () {
      if (isAuthenticated) {
        setEmail(null);
        setPassword(null);
        navigate("/app/home", { replace: true });
      }
    },
    [isAuthenticated]
  );

  if (isLoading) return <Spinner />;

  return (
    <main>
      <div className="FatForm">
        <form onSubmit={handleSubmit}>
          <div>
            <img
              src="/media/images/bunny.png"
              alt="Ceramic Bunny"
              className="LoginLogo"
            />
            <p>
              {loginMessage != null && loginMessage.trim() !== "" && (
                <Message thisstyle={"MSGError"}>{loginMessage}</Message>
              )}
            </p>
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>

          <div>
            <button className="BTN_Standard" type="primary">
              Login
            </button>

            <button
              className="BTN_Secondary"
              onClick={() => navigate(`/accountcreate`)}
            >
              Create an Account
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}
