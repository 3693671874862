import { FriendBlock } from "./FriendBlock";
import { friendRequests } from "../data/friendRequests";

export function FriendRequests() {
  return (
    <div className="FriendRequest">
      <h2>Connection Requests</h2>
      {friendRequests.map((friendReqs) => (
        <FriendBlock friend={friendReqs} key={friendReqs.id} />
      ))}
    </div>
  );
}
