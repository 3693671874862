function PageNotFound() {
  return (
    <>
      <section>
        <h1>No Está Aquí</h1>
        <p>That page cannot be found.</p>
      </section>
    </>
  );
}

export default PageNotFound;
