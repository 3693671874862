import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useLocations } from "../contexts/LocationsContext";
import { useStories } from "../contexts/StoriesContext";
import { useTags } from "../contexts/TagsContext";
function UserMenu() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { resetLocations } = useLocations();
  const { resetStories } = useStories();
  const { resetTags } = useTags();

  function handleLogout() {
    resetLocations();
    resetStories();
    resetTags();
    logout();

    navigate("/");
  }

  return (
    <div className="UserMenu">
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default UserMenu;
