export const friendRequests = [
  {
    id: "1",
    Name: "Booger Pressley",
    Picture: "/media/images/Booger.jpg",
    CommonFriends: 8,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "Terry", Picture: "/media/avatars/Avatar Users2_30.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Bruce Campbell",
    Picture:
      "/media/images/bruce-campbell_612x380_1-c01b51b2ccb049d0a271ad16ebe7d73e.jpg",
    CommonFriends: 18,
    Friends: [
      { Name: "Nancy", Picture: "/media/avatars/Avatar Users2_22.jpg" },
      { Name: "Zachary", Picture: "/media/avatars/Avatar Users2_29.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Groovy Bruce",
    Picture: "/media/images/bruce-campbell.webp",
    CommonFriends: 3,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "TBone", Picture: "/media/avatars/Avatar Users2_38.jpg" },
    ],
  },
  {
    id: "1",
    Name: "Sam Axe",
    Picture: "/media/images/BruceCampbell2015.webp",
    CommonFriends: 1,
    Friends: [
      { Name: "Larry", Picture: "/media/avatars/Avatar Users2_17.jpg" },
    ],
  },
];
