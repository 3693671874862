function StoryLocation(place) {
  console.log(place);
  return (
    <p>
      {place.place.LocName} | {place.place.LocAddress}
    </p>
  );
}

export default StoryLocation;
