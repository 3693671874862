import { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function ProfilePic() {
  const { user, updateProfilePic } = useAuth();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [files, setFiles] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setImage(event.target.files[0]);
    setFiles(event.target.files);
  };

  async function uploadFiles(e) {
    e.preventDefault();
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await fetch(`${baseUrl}/upload/profilepic/${user.CustUUID}`, {
      method: "POST",
      body: formData,
    });
    const data = await res.json();
    console.log(data.file);
    updateProfilePic(data.file);
    navigate(`/app/account`);
  }

  return (
    <section>
      <div className="PopForm showForm">
        <form
          id="uploadForm"
          encType="multipart/form-data"
          onSubmit={uploadFiles}
        >
          <h1>Edit/Upload New Profile Pic</h1>
          <label htmlFor="image-uload-input">
            {image ? image.name : "Choose an Image"}
          </label>
          <div onClick={handleImageClick}>
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                alt="Avatar"
                className="FormAvatar"
              />
            ) : (
              <img
                src="/media/avatars/Avatar_Std.png"
                alt="Avatar"
                className="FormAvatar"
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          <button className="BTN_Secondary">Upload</button>
        </form>
      </div>
    </section>
  );
}

export default ProfilePic;
