import LocationListStory from "../components/LocatonListStory";
import { useNavigate } from "react-router-dom";

function StoryMap() {
  const navigate = useNavigate();

  return (
    <>
      <section className="MapSection">
        <div className="AddLocationForm">
          <button className="MapButton" onClick={() => navigate(`/app/story`)}>
            Return To Stories
          </button>
        </div>
        <LocationListStory></LocationListStory>
      </section>
    </>
  );
}

export default StoryMap;
