export function Dialog({ message, onDialog }) {
  return (
    <div className="Dialog" onClick={() => onDialog(false)}>
      <div className="DialogText" onClick={(e) => e.stopPropagation()}>
        <h3>{message}</h3>
        <div className="Controls">
          <button onClick={() => onDialog(true)}>Yes</button>
          <button onClick={() => onDialog(false)}>No</button>
        </div>
      </div>
    </div>
  );
}
