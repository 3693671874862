import { useReducer } from "react";
import { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "../contexts/AuthContext";

//const BASE_URL = "https://bunnyapi.onrender.com";
//const BASE_URL = "http://localhost:5409";
const baseUrl = process.env.REACT_APP_BASE_URL;

const TagsContext = createContext();

const initialState = {
  tags: [],
  isLoading: false,
  currenttag: [],
  error: "",
};
// Add currenttags to initialState

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return { ...state, isLoading: true, error: "" };

    case "tags/loaded":
      return { ...state, isLoading: false, tags: action.payload, error: "" };

    case "tag/loaded":
      return {
        ...state,
        isLoading: false,
        currenttag: action.payload,
        error: "",
      };

    case "tag/created":
      return {
        ...state,
        isLoading: false,
        //tags: [...state.tags, action.payload],
        currenttag: action.payload,
        error: "",
      };

    case "tag/updated":
      //console.log(action.payload);
      //state.tags.map((tag) => console.log("tagid " + tag.tagid));
      const newtags = state.tags.map((tag) =>
        tag.tagid === action.payload.tagid
          ? {
              ...state.tags.tags,
              tagid: Number(action.payload.tagid),
              tagTitle: action.payload.tagTitle,
            }
          : tag
      );
      console.log("newtags: ");
      console.log(newtags);
      return {
        ...state,
        isLoading: false,
        tags: newtags, //<!--- Should be new?  Brackets?
        currenttag: action.payload,
      };

    case "tag/deleted":
      return {
        ...state,
        isLoading: false,
        tags: state.tags.filter((tag) => tag.tagid !== action.payload),
        currenttag: {},
      };
    case "reset":
      return {
        tags: [],
        isLoading: false,
        currenttag: [],
        error: "",
      };

    case "rejected":
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error("unknown action type");
  }
}

function TagsProvider({ children }) {
  const { user } = useAuth();
  const [{ tags, isLoading, currenttag, error }, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(
    function () {
      async function fetchtags() {
        dispatch({ type: "loading" });
        try {
          const res = await fetch(`${baseUrl}/tags/${user.CustUUID}`);
          const data = await res.json();
          dispatch({ type: "tags/loaded", payload: data });
          //console.log(data);
        } catch {
          dispatch({
            type: "rejected",
            payload: "There was an error loading data.",
          });
        }
      }
      fetchtags();
    },
    [user]
  );

  async function getTags(tagtype, itemid) {
    //if (Number(itemid) === currenttag.itemid) return;

    dispatch({ type: "loading" });
    try {
      const res = await fetch(
        `${baseUrl}/tags/${tagtype}/${itemid}/${user.CustUUID}`
      );
      const data = await res.json();
      if (data.message === "empty list") {
        dispatch({ type: "tag/loaded", payload: [] });
      } else {
        dispatch({ type: "tag/loaded", payload: data });
      }
    } catch (error) {
      dispatch({
        type: "rejected",
        payload: error + tagtype + itemid,
      });
    }
  }

  async function createtag(newtags, tagtype, itemid) {
    dispatch({ type: "loading" });

    // Find New
    // Post New
    // Take New with ID - add to Existing
    // Post IDs to Tag Assoc

    try {
      const res = await fetch(
        `${baseUrl}/tags/${tagtype}/${itemid}/${user.CustUUID}`,
        {
          method: "POST",
          body: JSON.stringify(newtags),
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await res.json();

      dispatch({ type: "tag/created", payload: newtags });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error adding tag data.",
      });
    }
  }

  async function updatetag(thetag, tagid) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/tags/${tagid}/${user.CustUUID}`, {
        method: "PUT",
        body: JSON.stringify(thetag),
        headers: { "Content-Type": "application/json" },
      });
      // Add SECOND Data Pull for tags...  currenttags   NO!!!  Cant have multiple Payloads...!? ????
      // tags needs its own Context... and own API Call.... !!!
      dispatch({ type: "tag/updated", payload: thetag });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error updating tag data.",
      });
    }
  }

  async function deletetag(tagid) {
    dispatch({ type: "loading" });
    try {
      const res = await fetch(`${baseUrl}/tags/${tagid}`, {
        method: "DELETE",
      });

      dispatch({ type: "tag/deleted", payload: tagid });
    } catch {
      dispatch({
        type: "rejected",
        payload: "There was an error deleting tag data.",
      });
    }
  }
  function resetTags() {
    dispatch({ type: "reset" });
  }
  return (
    <TagsContext.Provider
      value={{
        tags,
        isLoading,
        currenttag,
        error,
        getTags,
        createtag,
        updatetag,
        deletetag,
        resetTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}

function useTags() {
  const context = useContext(TagsContext);
  if (context === undefined)
    throw new Error("TagsContext used outside of tagsProvider");
  return context;
}
export { TagsProvider, useTags };
