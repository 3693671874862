import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../components/Spinner";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";

function AccountEdit() {
  const navigate = useNavigate();
  const { user, updateAccount } = useAuth();
  const [firstname, setFirstname] = useState(user.FirstName);
  const [lastname, setLastname] = useState(user.LastName);
  const [birthday, setBirthDay] = useState(user.birthday);
  const [email, setEmail] = useState(user.Email);
  const [company, setCompany] = useState(user.Company);
  const [profilePic, setProfilePic] = useState(user.ProfilePic);
  const [profileMasthead, setProfileMasthead] = useState(user.ProfileMasthead);
  const [phone1, setPhone1] = useState(user.Phone1);
  const [phone2, setPhone2] = useState(user.Phone2);
  const [address1, setAddress1] = useState(user.Address1);
  const [address2, setAddress2] = useState(user.Address2);
  const [city, setCity] = useState(user.City);
  const [province, setProvince] = useState(user.Province);
  const [zip, setZip] = useState(user.ZIP);
  const [country, setCountry] = useState(user.Country);
  const [webAddress, setWebAddress] = useState(user.WebAddress);
  const [success, setSuccess] = useState(null);

  const onPlaceholder = (e) => {
    setFirstname(e.target.value);
  };

  const onFNameChange = (e) => {
    setFirstname(e.target.value);
  };

  const onLNameChange = (e) => {
    setLastname(e.target.value);
  };

  const onBirthdayChange = (e) => {
    setBirthDay(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onProfilePicChange = (e) => {
    setProfilePic(e.target.value);
  };

  const onProfileMastheadChange = (e) => {
    setProfileMasthead(e.target.value);
  };

  const onBdayChange = (e) => {
    setBirthDay(e.target.value);
  };

  const onPhone1Change = (e) => {
    setPhone1(e.target.value);
  };

  const onPhone2Change = (e) => {
    setPhone2(e.target.value);
  };

  const onCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const onAddress1Change = (e) => {
    setAddress1(e.target.value);
  };

  const onAddress2Change = (e) => {
    setAddress2(e.target.value);
  };

  const onCityChange = (e) => {
    setCity(e.target.value);
  };

  const onProvinceChange = (e) => {
    setProvince(e.target.value);
  };

  const onZipChange = (e) => {
    setZip(e.target.value);
  };

  const onCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const onWebAddressChange = (e) => {
    setWebAddress(e.target.value);
  };

  async function handleSave(e) {
    e.preventDefault();
    console.log("Save!");

    let status = 1;

    if (!firstname || !lastname || !email || !birthday) return;
    const accountInfo = {
      firstname,
      lastname,
      birthday,
      email,
      company,
      profilePic,
      phone1,
      phone2,
      address1,
      address2,
      city,
      province,
      zip,
      country,
      webAddress,
    };

    await updateAccount(accountInfo);
    setSuccess("Account Updated!");
  }

  return (
    <section>
      {/* <button
        className="BTN_Secondary"
        onClick={() => navigate(`/app/accountedit/profilepic`)}
      >
        Edit Profile Pic
      </button>
      <button
        className="BTN_Secondary"
        onClick={() => navigate(`/app/accountedit/profilemasthead`)}
      >
        Edit Profile Masthead
      </button> */}
      {success != null && <Message thisstyle={"MSGSuccess"}>{success}</Message>}
      <form className="FatForm">
        <label htmlFor="firstname">First Name</label>
        <input
          type="text"
          name="firstname"
          value={firstname}
          onChange={onFNameChange}
          defaultValue={firstname}
          placeholder="First Name..."
        />

        <label htmlFor="lastname">Last Name</label>
        <input
          type="text"
          name="lastname"
          value={lastname}
          onChange={onLNameChange}
          defaultValue={lastname}
          placeholder="Last Name..."
        />

        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          value={email}
          onChange={onEmailChange}
          defaultValue={email}
          placeholder="Email..."
        />

        <label htmlFor="Company">Company</label>
        <input
          name="Company"
          type="text"
          value={company}
          onChange={onCompanyChange}
          defaultValue={company}
          placeholder="Company..."
        />

        <label htmlFor="ProfilePic">Profile Pic</label>
        <input
          name="ProfilePic"
          type="text"
          value={profilePic}
          onChange={onProfilePicChange}
          defaultValue={profilePic}
          placeholder="Profile Picture..."
        />

        <label htmlFor="ProfileMasthead">Profile Masthead</label>
        <input
          name="ProfileMasthead"
          type="text"
          value={profileMasthead}
          onChange={onProfileMastheadChange}
          defaultValue={profileMasthead}
          placeholder="Profile Masthead..."
        />

        <label htmlFor="Phone1">Phone 1</label>
        <input
          name="Phone1"
          type="text"
          value={phone1}
          onChange={onPhone1Change}
          defaultValue={phone1}
          placeholder="Phone 1..."
        />

        <label htmlFor="Phone2">Phone 2</label>
        <input
          name="Phone2"
          type="text"
          value={phone2}
          onChange={onPhone2Change}
          defaultValue={phone2}
          placeholder="Phone 2..."
        />

        <label htmlFor="Address1">Address 1</label>
        <input
          name="Address1"
          type="text"
          value={address1}
          onChange={onAddress1Change}
          defaultValue={address1}
          placeholder="Address 1..."
        />

        <label htmlFor="Address2">Address 2</label>
        <input
          name="Address2"
          type="text"
          value={address2}
          onChange={onAddress2Change}
          defaultValue={address2}
          placeholder="Address 2..."
        />

        <label htmlFor="City">City</label>
        <input
          name="City"
          type="text"
          value={city}
          onChange={onCityChange}
          defaultValue={city}
          placeholder="City..."
        />

        <label htmlFor="State">State</label>
        <input
          name="Province"
          type="text"
          value={province}
          onChange={onProvinceChange}
          defaultValue={province}
          placeholder="State..."
        />

        <label htmlFor="ZIP">ZIP</label>
        <input
          name="IP"
          type="text"
          value={zip}
          onChange={onZipChange}
          defaultValue={zip}
          placeholder="ZIP..."
        />

        <label htmlFor="Country">Country</label>
        <input
          name="Country"
          type="text"
          value={country}
          onChange={onCountryChange}
          defaultValue={country}
          placeholder="Country..."
        />

        <label htmlFor="WebAddress">WebAddress</label>
        <input
          name="WebAddress"
          type="text"
          value={webAddress}
          onChange={onWebAddressChange}
          defaultValue={webAddress}
          placeholder="WebAddress..."
        />
        <label htmlFor="birthDay">Birthday</label>

        <DatePicker
          selected={birthday}
          onChange={(date) => setBirthDay(date)}
          dateFormat="yyyy-MM-dd"
          isClearable
        />
        <button className="BTN_Standard" onClick={handleSave}>
          Save & Continue
        </button>
      </form>
    </section>
  );
}

export default AccountEdit;
