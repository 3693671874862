export const memoryList = [
  {
    id: "tt1375666",
    Title: "Wausau East High School",
    StartDate: "1988",
    EndDate: "1992",
    Description:
      "I'm a lumberjack and I'm Okay. I sleep all night and I work all day.",
    icon: "/media/images/WausauEast_medium.jpg",
    MediaCount: 5,
    MessageCount: 105,
    FriendCount: 8,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "Terry", Picture: "/media/avatars/Avatar Users2_30.jpg" },
      { Name: "Kari", Picture: "/media/avatars/Avatar Users2_14.jpg" },
      { Name: "Mark", Picture: "/media/avatars/Avatar Users2_39.jpg" },
    ],
  },
  {
    id: "tt137156",
    Title: "UWSP Dorm Days",
    StartDate: "1992",
    EndDate: "1994",
    Description:
      "Smith Hall, UWSP.  The low of the low. But we were on the 4th floor... and we had a smoking wing.",
    icon: "/media/images/UW-Stevens_Point_Logo.jpg",
    MediaCount: 12,
    MessageCount: 22,
    FriendCount: 2,
    Friends: [
      { Name: "Nate", Picture: "/media/avatars/Avatar Users2_28.jpg" },
      { Name: "Terry", Picture: "/media/avatars/Avatar Users2_30.jpg" },
    ],
  },
  {
    id: "tt137156",
    Title: "A Really Special Event That Was Cool and Memorable.",
    StartDate: "July 16, 1995",
    EndDate: "",
    Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
    icon: "/media/images/beer.jpg",
    MediaCount: 0,
    MessageCount: 0,
    FriendCount: 12,
    Friends: [
      { Name: "Bob", Picture: "/media/avatars/Avatar Users2_18.jpg" },
      { Name: "Janette", Picture: "/media/avatars/Avatar Users2_12.jpg" },
      { Name: "Janette", Picture: "/media/avatars/Avatar Users2_15.jpg" },
      { Name: "Janette", Picture: "/media/avatars/Avatar Users2_32.jpg" },
    ],
  },
];
